import axios from '@/axios/globalaxios'

export const findQuotationByCondition = data =>{
    return axios({
        url: "/tradelab/marketPortfolioManagement/findQuotationByCondition",
        method: 'post',
        data:data
    })
}
export const findCashFlow = data =>{
    return axios({
        url: "/tradelab/transaction/findCashFlow",
        method: 'post',
        data:data
    })
}
export const orderTransactionInformation = data =>{
    return axios({
        url: "/tradelab/transaction/findTransactionInformation",
        method: 'post',
        data:data
    })
}
// 撤单
export const cancelOrder = data =>{
    return axios({
        url: "/tradelab/transaction/cancelOrder",
        method: 'post',
        data:data
    })
}

// 委托
export const findDelegation = data =>{
    return axios({
        url: "/tradelab/transaction/findDelegation",
        method: 'post',
        data:data
    })
}
// 盘后交易
export const findStopwatchByFtype = data =>{
    return axios({
        url: "/tradelab/afterTraderPrice/findStopwatchByType",
        method: 'post',
        data:data
    })
}
